import './App.scss';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Heading from 'components/atoms/Heading';
import MainLayout from 'components/templates/MainLayout';
// import Home from 'pages/Home';
// import Update from 'pages/Update';
import { store } from 'store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const App: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<MainLayout />}>
        {/* <Route
          key="router-home"
          path="/"
          element={<Home />}
        /> */}
        {/* <Route
          key="router-home"
          path="/update"
          element={<Update />}
        /> */}
        <Route
          key="router-notfound"
          path="*"
          element={(
            <div className="notfound">
              <Heading modifiers={['20x30', '700', 'center']}>
                {/* Not found! */}
                Current Page Is Not Available!
              </Heading>
            </div>
          )}
        />
      </Route>
    </Routes>
  </BrowserRouter>
);

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);

export default AppWrapper;
